import {useEffect, useState} from 'react';
import debounce from 'lodash.debounce';

export default function useWindowHeight() {
  const [height, setHeight] = useState(window.innerHeight);

  const updateHeight = () => {
    const newHeight = window.innerHeight;

    setHeight((prev) => {
      // на мобильных в рассчет иногда идет панелька десятки пиксей, чтобы отсечь этот скачек при пролистывании
      // значение может быть отрицательным при меньшем размере, например смене ориентации устройства
      if (Math.abs(prev - newHeight) > 80) {
        console.log('-- height changed');
        console.log('-- newHeight');
        console.log(newHeight);
        return newHeight;
      }
      return prev;
    });
  };

  useEffect(() => {
    window.addEventListener('resize', debounce(updateHeight, 200), false);
    return () =>
      window.removeEventListener('resize', debounce(updateHeight, 200), false);
  }, []);

  return height;
}
