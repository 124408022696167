import './icon.scss';
import {ReactComponent as IconPlace} from './place.svg';
import {ReactComponent as IconStar} from './star.svg';
import {ReactComponent as IconStar1} from './star1.svg';
import {ReactComponent as IconStar5} from './star5.svg';
import {ReactComponent as IconPhoneRing} from './phonering.svg';
import {ReactComponent as IconArrow} from './arrow.svg';
import {ReactComponent as IconArrowThinkRight} from './arrowthink-right.svg';
import {ReactComponent as IconArrowThinkLeft} from './arrowthink-left.svg';
import {ReactComponent as IconPlay} from './play.svg';
import {ReactComponent as IconPresent} from './present.svg';
import {ReactComponent as IconCheck} from './check.svg';
import {ReactComponent as IconTelegram} from './telegram.svg';
import {ReactComponent as IconWhatsapp} from './whatsapp.svg';
import {ReactComponent as IconViber} from './viber.svg';
import {ReactComponent as IconInstagram} from './instagram.svg';

const icons = {
  place: <IconPlace />,
  present: <IconPresent />,
  star: <IconStar />,
  'star-1': <IconStar1 />,
  'star-5': <IconStar5 />,
  phoneRing: <IconPhoneRing />,
  arrow: <IconArrow />,
  arrowThinkRight: <IconArrowThinkRight />,
  arrowThinkLeft: <IconArrowThinkLeft />,
  check: <IconCheck />,
  play: <IconPlay />,
  telegram: <IconTelegram />,
  whatsapp: <IconWhatsapp />,
  viber: <IconViber />,
  instagram: <IconInstagram />
};

export default function Icon({name = ''}) {
  if (!name.length && !(name in icons)) {
    return null;
  }

  return <>{icons[name]}</>;
}
