import React from 'react';
import * as cn from 'classnames';
import {IoAddCircle as IconClose} from 'react-icons/io5';
import './InfoBox.scss';

// :TODO create this logics to checkbox:checked?
export default function InfoBox({text, description, showing, setShow}) {
  return (
    <>
      <div
        className={'info-icon'}
        onClick={(e) => {
          setShow(true);
          e.stopPropagation();
        }}
      >
        ?
      </div>

      <div
        className={cn('info-box', showing ? 'active' : '')}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={'close'} onClick={() => setShow(false)}>
          <IconClose />
        </div>
        <p dangerouslySetInnerHTML={{__html: description}} />
        <p dangerouslySetInnerHTML={{__html: text}} />
      </div>
    </>
  );
}
