import {useLayoutEffect, useState} from 'react';
import Slider from 'react-slick';
import * as cn from 'classnames';
import Container from '../Container';
import sliderConfig from './sliderConfig';
import {dataPromo} from '../../data/dataPromo';
import ButtonPlay from '../ButtonPlay';
import ModalFeedBack from '../modals/ModalFeedBack';
import useWindowHeight from '../../hooks/useHeight';
import 'slick-carousel/slick/slick.scss';
import '../../styles/blocks/slider-common.scss';
import '../../styles/blocks/slider-buttons.scss';
import './SliderPromo.scss';

export default function SliderPromo() {
  const [showModal, setShowModal] = useState(false);
  const height = useWindowHeight();

  return (
    <Container>
      <ModalFeedBack
        title={'Обратный звонок'}
        opened={showModal}
        setShowModal={setShowModal}
      />

      <div className={'slider-promo-play-btn'}>
        <ButtonPlay />
      </div>

      <Slider {...sliderConfig} className={'slider-promo'}>
        {dataPromo?.length &&
          dataPromo.map(({id, title, subtitle, text, imageUrl, products}) => {
            return (
              <div key={id} className={'slide'}>
                <div
                  className={'slide__col'}
                  style={{
                    height: `${height - 20}px`
                  }}
                >
                  {subtitle?.length && (
                    <div className={'slide__subtitle'}>
                      <p
                        className={'badge'}
                        dangerouslySetInnerHTML={{__html: subtitle}}
                      />
                    </div>
                  )}
                  {title?.length && (
                    <div className={'slide__title'}>
                      <h1 dangerouslySetInnerHTML={{__html: title}} />
                    </div>
                  )}
                  {text?.length && (
                    <div className={'slide__text'}>
                      <p dangerouslySetInnerHTML={{__html: text}} />
                    </div>
                  )}
                  <div className={'slide__footer'}>
                    <button
                      className={'btn btn--lg btn-primary btn--e-moveup'}
                      onClick={() => setShowModal(true)}
                    >
                      <span className={'btn-primary__bg'} />
                      <span className={'btn-primary__text'}>
                        Обратный звонок
                      </span>
                    </button>
                  </div>
                </div>
                {/* image */}
                <div
                  className={'slide__col'}
                  style={{
                    height: `${height - 20}px`
                  }}
                >
                  {imageUrl?.length && (
                    <div className={'slide__image'}>
                      <img
                        src={imageUrl}
                        alt={subtitle}
                        width={'428'}
                        height={'811'}
                        className={'slide__image animate-floating'}
                      />
                    </div>
                  )}
                  <div className={'slide__info'}>
                    {/* List products to current slide */}
                    {products?.length &&
                      products.map(
                        ({
                          id: prodId,
                          title: prodTitle,
                          description: prodDescription,
                          text: prodText
                        }) => {
                          return (
                            <div key={prodId} className={'slide__info-text'}>
                              <strong>{prodTitle}</strong>
                              <p>{prodDescription}</p>
                              <p dangerouslySetInnerHTML={{__html: prodText}} />
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </Container>
  );
}
