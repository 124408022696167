import './header.scss';
import Logo from '../Logo';
import Container from '../Container';
import Icon from '../Icon';
import {dataSettings} from '../../data/dataSettings';
import {clearPhone} from '../../lib/functions';

export default function Header() {
  return (
    <header className="header">
      <Container>
        <header className="header__inner">
          <div className={'header__contact'}>
            <div className={'header__contact-icon'}>
              <Icon name={'place'} />
            </div>
            <div className={'header__contact-text'}>
              <strong>{dataSettings.city}</strong>,<p>{dataSettings.address}</p>
            </div>
          </div>
          <div className={'header__logo'}>
            <Logo />
          </div>
          <div className={'header__contact'}>
            <div className={'header__contact-icon'}>
              <Icon name={'phoneRing'} />
            </div>
            <div className={'header__contact-text'}>
              <strong>
                <a href={`tel: ${clearPhone(dataSettings.phoneNumber)}`}>
                  {dataSettings.phoneNumber}
                </a>
              </strong>
              <p>{dataSettings.schedule}</p>
            </div>
          </div>
        </header>
      </Container>
    </header>
  );
}
