import './DeliveryPayment.scss';
import Container from '../Container';

export default function DeliveryPayment() {
  return (
    <section className={'section delivery'}>
      <Container>
        <div className={'delivery__heading heading'}>
          <h2>Доставка и оплата</h2>
        </div>

        <div className={'delivery__content'}>
          <div className={'delivery__shipping'}>
            <div className={'delivery__shipping-image'}>
              <img
                src={'/images/delivery-min.png'}
                width={355}
                height={180}
                alt={'delivery'}
                loading="lazy"
              />
            </div>
            <div className={'delivery__shipping-text'}>
              <span className={'delivery__content-title h4'}>
                Самовывоз из шоурума
              </span>
              <p>
                Вы можете лично забрать Ваш заказ из города Николаев, по адресу:
                г. Николаев, пр-т Центральный 78а
              </p>
              <span className={'delivery__content-title h4'}>Новая Почта</span>
              <p>
                При оформлление заказа через сайт, укажите пожалуйста город
                доставки и номер отделения Новой почты
              </p>
            </div>
          </div>
          <div className={'delivery__payment'}>
            <div className={'delivery__payment-image'}>
              <img
                src={'/images/cards-min.png'}
                width={245}
                height={220}
                alt={'payment'}
                loading="lazy"
              />
            </div>
            <div className={'delivery__payment-text'}>
              <span className={'delivery__content-title h4'}>
                Есть 2 способа оплатить выбранный товар:
              </span>
              <ul>
                <li>
                  <p>100% предоплата на карту Приват Банка или Моно Банка</p>
                </li>
                <li>
                  <p>
                    Наложенным платежом при доставке Новой Почтой. Минимальная
                    предоплата на карту за доставку – 100 грн
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
