import './StepStatus.scss';
import Icon from '../../Icon';

const StepStatus = ({num = 0, title = '', type = null}) => {
  switch (type) {
    case 'present':
      return (
        <div className={'step-status'}>
          <div className={'step-status__step-num'}>
            <Icon name={'present'} />
          </div>
          <div className={'step-status__title h3'}>{title}</div>
        </div>
      );
    default:
      return (
        <div className={'step-status'}>
          <div className={'step-status__step-num'}>{num}</div>
          <div className={'step-status__title h3'}>{title}</div>
        </div>
      );
  }
};

export default StepStatus;
