export const dataPromo = [
  {
    id: 1,
    title: 'Будь модной <br />и совершенной!',
    subtitle: 'Дизайнерская одежда для спорта',
    text: 'С мультибрендовым магазином женской спортивной одежды - <strong>Dress to Express</strong>',
    imageUrl: '/images/promo/girl1-min.png',
    products: [
      {
        id: 1,
        title: 'Топ Shantal',
        description:
          'Безшовный мягкий эластичный топ для активных занятий спортом',
        text: 'Размеры: обхват груди <br />XS-S до 90 см<br />М-L 91-100 см'
      },
      {
        id: 2,
        title: 'Hight Waist Slim Fit',
        description: 'Бесшовные легинсы с утяжкой, эластичные и мягкие.',
        text: 'Размеры: обхват бедер <br />XS - S 85-103 см <br />L - XL 104 - 115 см'
      }
    ]
  },
  {
    id: 2,
    title: 'Будь модной <br />и совершенной!',
    subtitle: 'Дизайнерская одежда для спорта',
    text: 'С мультибрендовым магазином женской спортивной одежды - <strong>Dress to Express</strong>',
    imageUrl: '/images/promo/girl2-min.png',
    products: [
      {
        id: 1,
        title: 'Майка Fit-Beats желтая',
        description:
          'Майка свободного фасона, легкая и дышащая, с текстурой в сеточку, быстро сохнет и отводит влагу.',
        text: 'Размеры: обхват груди <br />S 85-92 см <br />M 93-98 см L 99-104 см'
      },
      {
        id: 2,
        title: 'Black Cat Leggins',
        description:
          'Утягивающие легинсы, с компрессией по всей длине. Посадка высокая, не просвечивают, плоские надежные швы, вставка — одна полоска сеточки.',
        text: 'Размеры: обхват бедер <br />XS 85-95 см S 96-102 см <br />M 103-110 см L 111-117 см'
      }
    ]
  }
];
