import IndexPage from './pages/IndexPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Agreement from './pages/Agreement';

export const INDEX_ROUTE = '/';
export const PRIVATE_POLICY_ROUTE = '/private-policy';
export const USER_AGREEMENT_ROUTE = '/user-agreement';

export const publicRoutes = [
  {
    path: INDEX_ROUTE,
    Component: IndexPage
  },
  {
    path: PRIVATE_POLICY_ROUTE,
    Component: PrivacyPolicy
  },
  {
    path: USER_AGREEMENT_ROUTE,
    Component: Agreement
  }
];
