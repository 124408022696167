export const dataProducts = [
  {
    id: 1,
    title: 'Выберите топ',
    catTitle: 'Топы',
    products: [
      {
        id: 1,
        title: 'Топ Shantal',
        description:
          'Безшовный мягкий эластичный топ для активных занятий спортом',
        text: 'Размеры: обхват груди <br />XS-S до 90 см <br />М-L 91-100 см',
        price: 530,
        percent: 40,
        reviews: 285,
        stars: 4.5,
        image: [
          {
            url: '/images/products/top_shantal.jpg'
          }
        ]
      },
      {
        id: 2,
        title: 'Топ Evolution',
        description:
          'Безшовный мягкий эластичный топ для активных занятий спортом',
        text: 'Размеры: обхват груди <br />XS-S до 88 см <br />М-L 89-98 см',
        price: 530,
        percent: 40,
        reviews: 436,
        stars: 5,
        image: [
          {
            url: '/images/products/top_evolution.jpg'
          }
        ]
      },
      {
        id: 3,
        title: 'Топ Juicy',
        description:
          'Безшовный мягкий эластичный топ для активных занятий спортом',
        text: 'Размеры: обхват груди <br />XS-S до 90 см <br />М-L 91-100 см',
        price: 530,
        percent: 40,
        reviews: 193,
        stars: 4.1,
        image: [
          {
            url: '/images/products/top_juicy.jpg'
          }
        ]
      }
    ]
  },
  {
    id: 2,
    title: 'Выберите майку',
    catTitle: 'Майки',
    products: [
      {
        id: 1,
        title: 'Майка Fit-Beats фуксия',
        description:
          'Майка свободного фасона, легкая и дышащая, с текстурой в сеточку, быстро сохнет и отводит влагу.',
        text: 'Размеры: обхват груди <br />S 85-92 см <br />M 93-98 см <br />L 99-104 см',
        price: 650,
        percent: 40,
        reviews: 517,
        stars: 5,
        image: [
          {
            url: '/images/products/mayka_fucsia.jpg'
          }
        ]
      },
      {
        id: 2,
        title: 'Майка Fit-Beats черная',
        description:
          'Майка свободного фасона, легкая и дышащая, с текстурой в сеточку, быстро сохнет и отводит влагу.',
        text: 'Размеры: обхват груди <br />S 85-92 см <br />M 93-98 см <br />L 99-104 см',
        price: 650,
        percent: 40,
        reviews: 784,
        stars: 5,
        image: [
          {
            url: '/images/products/mayka_black.jpg'
          }
        ]
      },
      {
        id: 3,
        title: 'Майка Fit-Beats желтая',
        description:
          'Майка свободного фасона, легкая и дышащая, с текстурой в сеточку, быстро сохнет и отводит влагу.',
        text: 'Размеры: обхват груди <br />S 85-92 см <br />M 93-98   L 99-104 см',
        price: 650,
        percent: 40,
        reviews: 861,
        stars: 5,
        image: [
          {
            url: '/images/products/mayka_yellow.jpg'
          }
        ]
      }
    ]
  },
  {
    id: 3,
    title: 'Выберите леггинсы',
    catTitle: 'Легенсы',
    products: [
      {
        id: 1,
        title: 'Black Cat Leggins',
        description:
          'Утягивающие легинсы, с компрессией по всей длине. Посадка высокая, не просвечивают, плоские надежные швы, вставка — одна полоска сеточки.',
        text: 'Размеры: обхват бедер <br />XS 85-95     S 96-102 см <br />M 103-110 см      L 111-117 см',
        price: 1150,
        priceComplect: 1500,
        percent: 35,
        reviews: 517,
        stars: 5,
        image: [
          {
            url: '/images/products/leggins_black_cat.jpg'
          }
        ]
      },
      {
        id: 2,
        title: 'Nuance Jet Black Leggins',
        description:
          'Легинсы с утяжкой, не светятся, плоские швы "флэт лок". V-образный вырез на поясе, складка-резинка на ягодицах Brazzilian Butt. Нижний манжет для фиксации пяточки.',
        text: 'Размеры: обхват бедер <br />XS 83-88 см      S 89-94 см <br />M 95-102 см      L 103-110 см',
        price: 980,
        priceComplect: 1500,
        percent: 20,
        reviews: 837,
        stars: 5,
        image: [
          {
            url: '/images/products/leggins_nuance_jet_black.jpg'
          }
        ]
      },
      {
        id: 3,
        title: 'Mon Cheri Leggins',
        description:
          'Легинсы с самой высокой посадкой. Компрессия по всей длине, не просвечивают, вставка- две полоски сеточки по бедру.',
        text: 'Размеры: обхват бедер <br />S 85-94 см         M 95-102 см <br />L 103-110 см        XL 111-117 см',
        price: 1150,
        priceComplect: 1500,
        percent: 35,
        reviews: 903,
        stars: 5,
        image: [
          {
            url: '/images/products/leggins_mon_cheri.jpg'
          }
        ]
      },
      {
        id: 4,
        title: 'Hight Waist Slim Fit',
        description: 'Бесшовные легинсы с утяжкой, эластичные и мягкие.',
        text: 'Размеры: обхват бедер <br />XS-S 85-103 см <br />L-XL 104-115 см',
        price: 600,
        priceComplect: 1300,
        percent: 0,
        reviews: 1299,
        stars: 5,
        image: [
          {
            url: '/images/products/leggins_hight_waist_slim.jpg'
          }
        ]
      }
    ]
  }
];

export const dataExtendSteps = {
  finally: {
    id: 4,
    title: 'Выберите подарок',
    products: [
      {
        id: 1,
        title: 'Banana Bag',
        description:
          'Сумка на пояс Banana Bag идеально подойдет для пробежки. В нее поместится телефон и ключи.',
        price: 500,
        image: [
          {
            url: '/images/products/banana_bag-min.jpg'
          }
        ]
      },
      {
        id: 2,
        title: 'Рюкзак Naomi',
        description:
          'В рюкзак Naomi поместиться все необходимое для тренировки: кроссовки, полотенце, спортивная одежда.',
        price: 500,
        image: [
          {
            url: '/images/products/naomi-min.jpg'
          }
        ]
      }
    ]
  },
  corset: {
    id: 5,
    products: [
      {
        id: 1,
        title: 'Komplique Sport Fit Care Corset',
        price: 500,
        image: [
          {
            url: '/images/products/sport_fit_care_corset.jpg'
          }
        ]
      }
    ]
  }
};
