export const dataAbout = {
  title:
    'Дизайнерская одежда для результативных занятий спортом от Чемпионки Украины!',
  subtitle: 'будь модным и стильным с Dress to Express!',
  description:
    'Сама идея одевать активных украинок в красивую дизайнерскую спортивную одежду принадлежит Чемпионке Украины, финалистке Чемпионата Европы, сертифицированному тренеру с 20-летним опытом работы Алле Чернышовой',
  text: '<strong>Dress to Express</strong> – мультибрендовый магазин женской спортивной одежды. Официальный представитель Komplique Sport - украино-американской компании производителя высокотехнологичной спортивной женской одежды. Мы создаем коллекции не только для профессиональных спортсменок, но и для всех поклонниц активного образа жизни. В каждом изделии – идеальное сочетание стиля и новейших технологий.',
  textFooter:
    'Наши онлайн-консультанты помогут Вам подобрать комплект с учетом Ваших тренировок для наиболее эффективных занятий нацеленных на результат!',
  person: {
    name: 'Алла Чернышова',
    status: 'Чемпионка Украины'
  }
};
