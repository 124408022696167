import React from 'react';
import {dataQuiz} from '../../../data/dataQuiz';
import './QuizStatus.scss';

export default function QuizStatus({
  activeProducts,
  status: quizStatus,
  countSteps
}) {
  // console.log('-- countSteps');
  // console.log(countSteps);

  const getStatusText = (num) => {
    switch (num) {
      case 3:
        return dataQuiz.status.choice.noselect;
      case 2:
        return dataQuiz.status.choice.step2;
      case 1:
        return dataQuiz.status.choice.step1;
      default:
        return '';
    }
  };

  const leftSteps = countSteps - activeProducts.length;

  const status = () => {
    switch (quizStatus) {
      case 'choice':
        if (leftSteps === 0) {
          return dataQuiz.status.finally;
        }
        return getStatusText(leftSteps);
      case 'finally':
        return dataQuiz.status.finally;
      case 'success':
        return <strong>{dataQuiz.status.success}</strong>;
      default:
        return '';
    }
  };

  const progressWidth = () => {
    switch (quizStatus) {
      case 'choice':
        return (100 / countSteps) * activeProducts.length;
      case 'finally':
        return 100;
      case 'success':
        return 100;
      default:
        return 0;
    }
  };

  return (
    <div className={'quiz-status'}>
      <div
        className={'quiz-progress'}
        style={{
          width: `${progressWidth()}%`
        }}
      />

      <div className={'quiz-status__text'}>{status()}</div>
      <div className={'quiz-status__ico'}>
        <img
          src={'/images/ico_present-min.png'}
          alt={'present'}
          width={'105'}
          height={'109'}
          loading="lazy"
        />
      </div>
    </div>
  );
}
