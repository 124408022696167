import {useState} from 'react';

export const useModal = () => {
  const [isOpen, setOpen] = useState(false);

  return {
    isOpen,
    setOpen: (opened) => setOpen(opened)
  };
};
