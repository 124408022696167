import React, {useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import Container from '../Container';
import {dataProducts} from '../../data/dataProducts';
import {dataQuiz} from '../../data/dataQuiz';
import StepChoice from './Steps/StepChoice';
import QuizButtons from './Buttons/QuizButtons';
import StepFinally from './Steps/StepFinally';
import QuizStatus from './QuizStatus';
import GradientEllipse from '../backgrounds/GradientEllipse';
import {dataSettings} from '../../data/dataSettings';
import './Quiz.scss';
import {useModal} from '../modals/useModal';
import Modal from '../modals/Modal';
import TableSizes from '../TableSizes';

export default function Quiz() {
  // choice, finally, success
  const [status, setStatus] = useState('choice');
  // !idx of array
  const [activeStep, setActiveStep] = useState(0);
  // {
  //   stepIdx: activeStep,
  //   activeItemId: 1
  // }
  const [activeProducts, setActiveProducts] = useState([]);
  const [price, setPrice] = useState(0);

  const dataStep = dataProducts[activeStep];
  const stepProducts = dataProducts[activeStep]?.products;
  const countSteps = dataProducts?.length;
  const countProducts = dataProducts[activeStep]?.products?.length;
  const modalSizes = useModal();

  useEffect(() => {
    if (status === 'finally') {
      setActiveStep(activeStep + 1);
    }
  }, [status]);

  const haveStepActiveProduct = () => {
    return activeProducts.findIndex((item) => item.stepIdx === activeStep) >= 0;
  };

  const ActiveStep = () => {
    switch (status) {
      case 'choice':
        return (
          <>
            {countProducts && (
              <>
                <StepChoice
                  dataStep={dataStep}
                  products={stepProducts}
                  activeStep={activeStep}
                  activeProducts={activeProducts}
                  setActiveProducts={setActiveProducts}
                  setPrice={setPrice}
                />
                <div className={'quiz__footer'}>
                  <div>
                    <div className={'quiz__table-size'}>
                      <button
                        className={'btn btn--md btn-third btn--e-scale'}
                        onClick={() => modalSizes.setOpen(true)}
                      >
                        Таблица размеров
                      </button>
                    </div>

                    {dataStep.id === 3 && (
                      <div className={'quiz__price'}>
                        <span className={'quiz__price-text'}>
                          Стоимость комплекта
                        </span>
                        <span className={'quiz__price-amount'}>
                          {`${price} ${dataSettings.priceCurrency}`}
                        </span>
                        {!price && (
                          <span className={'quiz__price-note'}>
                            *Выберите легенсы для комплекта!
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  <QuizButtons
                    activeStep={activeStep}
                    countProducts={countProducts}
                    countSteps={countSteps}
                    activeProducts={activeProducts}
                    setActiveStep={setActiveStep}
                    setStatus={setStatus}
                    haveStepActiveProduct={haveStepActiveProduct}
                  />
                </div>
              </>
            )}
          </>
        );
      case 'finally':
      case 'success':
        return (
          <>
            <StepFinally
              activeStep={activeStep}
              activeProducts={activeProducts}
              setActiveProducts={setActiveProducts}
              status={status}
              setStatus={setStatus}
              price={price}
              setPrice={setPrice}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <section className={'section quiz'}>
      <Container>
        <GradientEllipse
          type="third"
          width="800px"
          height="800px"
          top="-200px"
          left={'-250px'}
        />
        <GradientEllipse
          type="secondary"
          width="600px"
          height="600px"
          bottom="-250px"
          right={'-150px'}
        />
        {!isMobile && (
          <img
            className={'bg-item bg-item--1'}
            src="/images/bgitems/1-min.png"
            width="502"
            height="551"
            alt="item"
            loading="lazy"
          />
        )}
        {!isMobile && (
          <img
            className={'bg-item bg-item--2'}
            src="/images/bgitems/2-min.png"
            width="623"
            height="745"
            alt="item"
            loading="lazy"
          />
        )}
        {status && (
          <div className={'quiz__inner box-primary'}>
            {/* modal sizes */}
            <Modal
              isOpen={modalSizes.isOpen}
              onClose={() => modalSizes.setOpen(false)}
              className={'modal-sizes'}
            >
              <TableSizes type={activeStep} />
            </Modal>

            <div className={'quiz__subheading subheading'}>
              <p className={'badge'}>{dataQuiz.subtitle}</p>
            </div>
            <div className={'quiz__heading heading'}>
              <h2>
                {status === 'choice' && dataQuiz.title}
                {status === 'finally' &&
                  'Ваш уникальный лук собран! Вы можете выбрать бесплатный подарок!'}
                {status === 'success' && 'Ваш уникальный лук отправлен!'}
              </h2>
            </div>
            <div className={'quiz__content'}>
              <QuizStatus
                status={status}
                activeProducts={activeProducts}
                countProducts={countProducts}
                countSteps={countSteps}
                activeStep={activeStep}
              />
              <ActiveStep />
            </div>
          </div>
        )}
      </Container>
      ;
    </section>
  );
}
