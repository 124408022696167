import ReactDOM from 'react-dom';
import cn from 'classnames';
import React, {useRef} from 'react';
import {IoAddCircle as IconClose} from 'react-icons/io5';
import {CSSTransition} from 'react-transition-group';
import './Modal.scss';

export default function Modal({
  isOpen,
  onClose,
  children,
  overlayClickClose = true,
  buttonClose = true,
  className = '',
  overflow = 'auto'
}) {
  const modalRef = useRef();

  const overlayClick = ({target}) => {
    if (onClose && overlayClickClose) {
      if (modalRef.current.contains(target)) return;
      onClose();
    }
  };

  const modalContent = (
    <CSSTransition
      in={isOpen}
      timeout={200}
      classNames="modal-appearance"
      unmountOnExit
      // onEnter={() => console.log('-- transition onEnter')}
      // onExited={() => console.log('-- transition onExited')}
    >
      <div
        className={'modal-overlay modal-appearance'}
        style={{overflow}}
        onClick={overlayClick}
      >
        <div className={cn(className, 'modal')} ref={modalRef}>
          {buttonClose && (
            <div className={'modal-close'} onClick={() => onClose()}>
              <IconClose />
            </div>
          )}
          {children}
        </div>
      </div>
    </CSSTransition>
  );

  if (typeof window !== 'undefined') {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById('modal')
    );
  } else {
    return null;
  }
}
