import {useRef, useState} from 'react';
import Container from '../Container';
import Logo from '../Logo';
import YaMap from '../YaMap';
import {dataSettings} from '../../data/dataSettings';
import {clearPhone} from '../../lib/functions';
import './SectionContact.scss';
import ModalFeedBack from '../modals/ModalFeedBack';
import Icon from '../Icon';

export default function SectionContact() {
  const [showModal, setShowModal] = useState(false);
  const divMap = useRef(null);

  return (
    <section className={'section section-contact'}>
      <ModalFeedBack
        title={'Обратный звонок'}
        opened={showModal}
        setShowModal={setShowModal}
      />

      <Container>
        <div className={'section-contact__inner'}>
          <div className={'section-contact__content box-primary'}>
            <div className={'section-contact__logo'}>
              <Logo />
            </div>
            <div className={'section-contact__info'}>
              <div className={'contact-info'}>
                <div className={'contact-info__title h4'}>Адрес</div>
                <p>{dataSettings.fullAddress}</p>
              </div>
              <div className={'contact-info'}>
                <div className={'contact-info__title h4'}>График работы</div>
                <p>{dataSettings.schedule}</p>
              </div>
              <div className={'contact-info'}>
                <div className={'contact-info__title h4'}>Телефон</div>
                <p>
                  <a
                    className={'icon-box'}
                    href={`tel:${clearPhone(dataSettings.phoneNumber)}`}
                  >
                    <span className={'icon'}>
                      <Icon name={'telegram'} />
                    </span>
                    <span className={'icon'}>
                      <Icon name={'viber'} />
                    </span>
                    <span className={'icon'}>
                      <Icon name={'whatsapp'} />
                    </span>
                    {dataSettings.phoneNumber}
                  </a>
                </p>
              </div>
              <div className={'contact-info'}>
                <div className={'contact-info__title h4'}>Инстаграм</div>
                <p>
                  <a
                    className={'icon-box'}
                    href={dataSettings.socials?.instagram?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className={'icon'}>
                      <Icon name={'instagram'} />
                    </span>

                    {dataSettings.socials?.instagram?.title}
                  </a>
                </p>
              </div>
            </div>
            <div className={'section-contact__footer'}>
              <button
                className={'btn btn-primary btn--md btn--e-scale'}
                onClick={() => setShowModal(true)}
              >
                Обратный звонок
              </button>
              <p>Мы перезвоним Вам в течении 20 минут!</p>
            </div>
          </div>

          <div ref={divMap} className={'section-contact__map'}>
            <YaMap
              className={'ya-map'}
              refParent={divMap}
              settings={{
                latitude: dataSettings?.map?.latitude,
                longitude: dataSettings?.map?.longitude
              }}
            />
          </div>
        </div>
      </Container>
    </section>
  );
}
