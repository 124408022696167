import {BrowserRouter} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import GradientEllipse from './components/backgrounds/GradientEllipse';
import Header from './components/Header';
import './app.scss';
import Footer from './components/Footer';
import AppRouter from './components/AppRouter';
import TopBtn from './components/TopBtn';

export default function App() {
  return (
    <div className="app-body">
      <GradientEllipse
        type="primary"
        width="1200px"
        height="1200px"
        top="-500px"
        left="-500px"
      />

      <GradientEllipse
        type="secondary"
        width="1200px"
        height="1200px"
        top="-600px"
        right="-300px"
      />

      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <AppRouter />
        <TopBtn />
        <Footer />
      </BrowserRouter>
    </div>
  );
}
