import React, {useEffect} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import * as cn from 'classnames';
import {dataExtendSteps} from '../../../data/dataProducts';
import StepStatus from './StepStatus';
import QuizProduct from '../QuizProduct';
import './StepFinally.scss';
import {dataSettings} from '../../../data/dataSettings';
import Modal from '../../modals/Modal';
import Icon from '../../Icon';
import {useModal} from '../../modals/useModal';
import QuizFormSendProducts from '../QuizFormSendProducts';
import QuizExternalModal from '../QuizExternalModal';

function StepFinally({
  activeStep,
  activeProducts,
  setActiveProducts,
  setStatus,
  status,
  price
}) {
  const dataStep = dataExtendSteps.finally;
  const {products} = dataExtendSteps.finally;

  const activeProductIdx = activeProducts.findIndex(
    (item) => item.stepIdx === activeStep
  );

  const {isOpen: isOpenSendModal, setOpen: setOpenSendModal} = useModal();
  const {isOpen: isOpenExModal, setOpen: setOpenExModal} = useModal();
  const disabled = activeProductIdx < 0;

  // show external modal corset
  useEffect(() => {
    let timerSuccess = null;
    setOpenExModal(true);
    if (status === 'success') {
      timerSuccess = setTimeout(() => {
        setOpenExModal(true);
      }, 1000);
    }

    return () => {
      clearTimeout(timerSuccess);
    };
  }, [status]);

  const setActive = (id, priceComplete = 0) => {
    let newActiveProducts = null;

    const newActive = {
      stepIdx: activeStep,
      activeItemId: id
    };

    if (activeProductIdx >= 0) {
      newActiveProducts = [
        ...activeProducts.slice(0, activeProductIdx),
        newActive,
        ...activeProducts.slice(activeProductIdx + 1)
      ];
    } else {
      newActiveProducts = [...activeProducts, newActive];
    }

    setActiveProducts(newActiveProducts);
  };

  return (
    <div
      className={cn(
        'step-finally',
        status === 'success' ? 'success disabled' : ''
      )}
    >
      {/* show sending form */}
      {status === 'finally' && (
        <Modal isOpen={isOpenSendModal} onClose={() => setOpenSendModal(false)}>
          <QuizFormSendProducts
            title={'Квиз: Собери свой неповторимый лук'}
            activeProducts={activeProducts}
            price={price}
            setStatus={setStatus}
          />
        </Modal>
      )}
      {/* show external modal */}
      {status === 'success' && (
        <Modal
          isOpen={isOpenExModal}
          className={'quiz-external-modal'}
          onClose={() => setOpenExModal(false)}
          overlayClickClose={false}
        >
          <QuizExternalModal
            activeProducts={activeProducts}
            price={price}
            closeParent={() => setOpenExModal(false)}
          />
        </Modal>
      )}
      <StepStatus title={dataStep.title} type={'present'} />
      <div
        className={'step-finally__content'}
        style={{overflowX: isMobile || isTablet ? 'scroll' : 'auto'}}
      >
        <div className={'step-finally__inner'}>
          <div className={'step-finally__products'}>
            {products?.length &&
              products.map((product) => {
                return (
                  <QuizProduct
                    key={product.id}
                    {...product}
                    active={
                      activeProductIdx >= 0 &&
                      product.id ===
                        activeProducts[activeProductIdx].activeItemId
                    }
                    setActive={setActive}
                  />
                );
              })}
          </div>
        </div>
      </div>

      <div className={'quiz__footer justify-center'}>
        <div className={'step-finally__price'}>
          <div className={'quiz__price'}>
            <span className={'quiz__price-text'}>Стоимость комплекта</span>
            <span className={'quiz__price-amount'}>
              {`${price} ${dataSettings.priceCurrency}`}
            </span>
            {!price && (
              <span className={'quiz__price-note'}>
                *Выберите легенсы для комплекта!
              </span>
            )}
          </div>
        </div>

        <button
          className={'btn btn--md btn-primary btn--e-scale'}
          onClick={() => {
            setOpenSendModal(true);
          }}
          disabled={disabled}
        >
          <span>Отправить заказ</span>
          <span className={'icon icon--right'}>
            <Icon name={'arrowThinkRight'} />
          </span>
        </button>
      </div>
    </div>
  );
}

export default StepFinally;
