export const dataQuiz = {
  title: 'Собери свой неповторимый лук для эффективных тренировок!',
  subtitle: 'Выгодное предложение по супер цене!',
  status: {
    choice: {
      // 0
      noselect:
        'Подберите уникальный комплект и получите гарантированный подарок!',
      // 1
      step3: 'Осталось 3 шага до гарантированного подарка!',
      // 2
      step2: 'Осталось 2 шага до гарантированного подарка!',
      // 3
      step1: 'Остался 1 шаг до гарантированного подарка!'
    },
    finally: 'Вы можете выбрать бесплатный подарок!',
    success:
      'Спасибо за Ваш выбор! Наш менеджер свяжется с Вами в течении 20 минут!'
  }
};
