import React, {useEffect, useRef} from 'react';
import cn from 'classnames';
import {messages} from '../../../lib/messages';

export default function TextField({
  name,
  error,
  setError,
  deleteError,
  required = false,
  value = '',
  changeField
}) {
  const labelRef = useRef(null);

  const checkRequired = () => {
    if (required) {
      if (!value?.length) {
        setError(name, messages.ru.empty);
      } else {
        deleteError(name);
      }
    }
  };

  const onChange = (e) => {
    changeField(name, e.target.value);
    checkRequired();
  };

  const onFocus = (e) => {
    // console.log('--onFocus');
    labelRef.current.classList.add('focused');
  };

  const onBlur = (e) => {
    // console.log('--onBlur');
    checkRequired();

    if (!error && !value?.length) {
      labelRef.current.classList.remove('focused');
    }
  };

  return (
    <>
      <input
        id={`form-field-${name}`}
        name={name}
        type="text"
        className="form-field__input"
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        // autoComplete="off"
      />
      <label
        ref={labelRef}
        htmlFor={`form-field-${name}`}
        data-title={error || messages.ru.name}
        className={cn(
          'form-field__label',
          error ? 'error focused' : value.length ? 'focused' : ''
        )}
      />
    </>
  );
}
