import {useEffect, useRef, useState} from 'react';
import {YMaps, Map, Placemark} from 'react-yandex-maps';
import Loader from '../Loader';

export default function YaMap({refParent, className, settings = {}}) {
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const observer = useRef();

  useEffect(() => {
    const options = {
      // root: parentRef.current,
      rootMargin: '0px',
      treshold: 1
    };

    if (loading) {
      observer.current = new IntersectionObserver(([target]) => {
        if (target.isIntersecting) {
          setVisible(true);
        }
      }, options);

      observer.current.observe(refParent.current);
    }

    return () => {
      observer.current.disconnect(refParent.current);
    };
  });

  if (!settings) return null;

  const {latitude, longitude} = settings;
  if (!latitude || !longitude) return null;

  const coordinate = [latitude, longitude];

  const mapData = {
    center: coordinate,
    zoom: 17
    // behaviors: ["disable('scrollZoom')"]
  };

  if (!visible) return null;

  return (
    <>
      {loading && <Loader />}
      <YMaps>
        <Map
          defaultState={mapData}
          instanceRef={(ref) => {
            if (ref) {
              ref.behaviors.disable('scrollZoom');
            }
          }}
          className={className}
          onLoad={() => setLoading(false)}
        >
          <Placemark
            geometry={coordinate}
            // properties={{
            //   iconContent: 'text message'
            // }}
            options={{
              iconLayout: 'default#image',
              iconImageHref: '/images/map-marker.png',
              iconImageSize: [48, 80],
              iconImageOffset: [-24, -70]
            }}
          />
        </Map>
      </YMaps>
    </>
  );
}
