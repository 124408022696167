import React from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import './TableSizes.scss';

const sizes = {
  0: (
    <>
      <div className={'row'}>
        <div className={'table-sizes__heading'}>Название товара</div>
        <div className={'table-sizes__heading'}>XS - S</div>
        <div className={'table-sizes__heading'}>M - L</div>
      </div>
      <div className={'row'}>
        <div>Топ Shantal</div>
        <div>ОГ до 90 см</div>
        <div>ОГ 91-100 см</div>
      </div>
      <div className={'row'}>
        <div>Топ Evolution</div>
        <div>ОГ до 88 см</div>
        <div>ОГ 89-98 см</div>
      </div>
      <div className={'row'}>
        <div>Топ Juicy</div>
        <div>ОГ до 90 см</div>
        <div>ОГ 91-100 см</div>
      </div>
    </>
  ),
  1: (
    <>
      <div className={'row'}>
        <div className={'table-sizes__heading'}>Название товара</div>
        <div className={'table-sizes__heading'}>S</div>
        <div className={'table-sizes__heading'}>M</div>
        <div className={'table-sizes__heading'}>L</div>
      </div>
      <div className={'row'}>
        <div>Майка Fit-Beats фуксия</div>
        <div>ОГ 85-92 см</div>
        <div>ОГ 93-98 см</div>
        <div>ОГ 99-104 см</div>
      </div>
      <div className={'row'}>
        <div>Майка Fit-Beats черная</div>
        <div>ОГ 85-92 см</div>
        <div>ОГ 93-98 см</div>
        <div>ОГ 99-104 см</div>
      </div>
      <div className={'row'}>
        <div>Майка Fit-Beats желтая</div>
        <div>ОГ 85-92 см</div>
        <div>ОГ 93-98 см</div>
        <div>ОГ 99-104 см</div>
      </div>
    </>
  ),
  2: (
    <>
      <div className={'row'}>
        <div className={'table-sizes__heading'}>Название товара</div>
        <div className={'table-sizes__heading'}>XS</div>
        <div className={'table-sizes__heading'}>S</div>
        <div className={'table-sizes__heading'}>M</div>
        <div className={'table-sizes__heading'}>L</div>
        <div className={'table-sizes__heading'}>XL</div>
      </div>
      <div className={'row'}>
        <div>Black Cat Leggins</div>
        <div>ОБ 85-95 см</div>
        <div>ОБ 96-102 см</div>
        <div>ОБ 103-110 см</div>
        <div>ОБ 111-117 см</div>
        <div>---</div>
      </div>
      <div className={'row'}>
        <div>Nuance Jet Black Leggins</div>
        <div>ОБ 83-88 см</div>
        <div>ОБ 89-94 см</div>
        <div>ОБ 95-102 см</div>
        <div>ОБ 103-110 см</div>
        <div>---</div>
      </div>
      <div className={'row'}>
        <div>Mon Cheri Leggins</div>
        <div>---</div>
        <div>ОБ 85-94 см</div>
        <div>ОБ 95-102 см</div>
        <div>ОБ 111-117 см</div>
        <div>ОБ 111-117 см</div>
      </div>
      <div className={'row'}>
        <div>Hight Waist Slim Fit</div>
        <div>XS - S</div>
        <div>ОБ 85-103 см</div>
        <div />
        <div>L - XL</div>
        <div>ОБ 104-115 см</div>
      </div>
    </>
  )
};
export default function TableSizes({type = null}) {
  return (
    <div
      className={'table-sizes'}
      style={{overflowX: isMobile || isTablet ? 'scroll' : 'auto'}}
    >
      {type in sizes && sizes[type]}
    </div>
  );
}
