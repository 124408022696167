import React from 'react';
import './FormStatus.scss';
import Loader from '../Loader';
import {messages} from '../../../lib/messages';

export default function FormStatus({status}) {
  if (status === 'submit') {
    return (
      <div className="form-status form-status__sending">
        <Loader />
        {messages.ru.sending}
      </div>
    );
  } else if (status === 'error') {
    return (
      <div className="form-status form-status__error">
        {messages.ru.sendingError}
      </div>
    );
  } else if (status === true) {
    return (
      <div className="form-status form-status__success">
        {messages.ru.sendingSuccess}
      </div>
    );
  } else {
    return null;
  }
}
