import React from 'react';
import * as cn from 'classnames';
import './gradientEllipse.scss';

const GradientEllipse = ({
  type = '',
  width = '100px',
  height = '100px',
  top = 'auto',
  left = 'auto',
  right = 'auto',
  bottom = 'auto'
}) => {
  return (
    <div
      className={cn(
        'gradient-ellipse',
        type.length ? `gradient-ellipse--${type}` : ''
      )}
      style={{
        width: `${width}`,
        height: `${height}`,
        left: `${left}`,
        top: `${top}`,
        right: `${right}`,
        bottom: `${bottom}`
      }}
    />
  );
};

export default GradientEllipse;
