import * as cn from 'classnames';
import {useState, useEffect} from 'react';
import {postForm} from '../../../lib/queries';
import PhoneField from '../PhoneField';
import TextField from '../TextField';
import './FormPrimary.scss';
import FormStatus from '../FormStatus';
import CheckAgree from '../CheckAgree';

const urlPost = 'https://d2emailer.ipcoder.ru/post.php';
// const urlPost = 'http://d2email.loc/post.php';

export const initFields = {
  firstName: {
    name: 'firstName',
    placeholder: 'Ваше имя',
    value: ''
  },
  phone: {
    name: 'phone',
    placeholder: 'Телефон',
    required: true,
    value: ''
  },
  agree: {
    name: 'agree',
    required: true,
    value: true
  }
};

export default function FormPrimary({
  disabled = false,
  action = null,
  title = '',
  data = null,
  onSuccess
}) {
  const [fields, setFields] = useState(initFields);
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState(false);

  useEffect(async () => {
    let unmount = false;

    const sendForm = async () => {
      const response = await postForm({
        url: urlPost,
        action,
        body: {
          title,
          dataForm: fields,
          data: {...(data?.data || null)},
          price: data?.price || ''
        }
      });

      console.log('-- Post Form response');
      console.log(response);

      // success sending
      if (!unmount) {
        if (response?.status) {
          setStatus(true);
          // callback
          onSuccess();
          // reset data
          setFields(initFields);
        } else {
          setStatus('error');
        }
      }
    };

    if (status === 'submit') {
      await sendForm();
    }

    return () => {
      unmount = true;
    };
  }, [status]);

  const changeField = (fieldName, value) => {
    if (!fieldName) return;

    setFields((prev) => {
      return {
        ...fields,
        [fieldName]: {
          ...fields[fieldName],
          value
        }
      };
    });
  };

  const setError = (fieldName, message) => {
    if (fieldName) {
      setErrors((prev) => {
        return {
          ...prev,
          [fieldName]: message
        };
      });
    }
  };

  const deleteError = (fieldName) => {
    if (!fieldName) return;
    const newErrors = errors;
    delete newErrors[fieldName];
    setErrors(newErrors);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setStatus('submit');
  };

  return (
    <form
      className={cn(
        'form-primary',
        disabled || status === 'submit' ? 'disabled form-sending' : ''
      )}
      noValidate={'novalidate'}
    >
      <div className="form-field">
        <TextField
          {...fields.firstName}
          changeField={changeField}
          error={!disabled && errors?.firstName ? errors.firstName : false}
          setError={setError}
          deleteError={deleteError}
        />
      </div>

      <div className="form-field">
        <PhoneField
          {...fields.phone}
          changeField={changeField}
          error={!disabled && errors?.phone ? errors.phone : null}
          setError={setError}
          deleteError={deleteError}
        />
      </div>

      <div className="form-field">
        <CheckAgree
          {...fields.agree}
          changeField={changeField}
          error={!disabled && errors?.agree ? errors.agree : null}
          setError={setError}
          deleteError={deleteError}
        />
      </div>
      {status && <FormStatus status={status} />}
      <div className="form-field form-field__submit">
        <button
          className={'btn btn-primary btn--md btn--e-scale'}
          onClick={onSubmit}
          type={'submit'}
          disabled={!!Object.keys(errors).length || status === 'submit'}
        >
          Отправить
        </button>
      </div>
    </form>
  );
}
