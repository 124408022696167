export const messages = {
  ru: {
    name: 'Ваше Имя',
    phone: 'Телефон',
    empty: 'Обязательное',
    sending: 'Отправка',
    sendingError: 'Ошибка отправки, повторите отправку',
    sendingSuccess: 'Заявка отправлена, мы свяжемся с Вами в ближайшее время',
    invalidValue: 'Неверное значение',
    invalidPhone: 'Неккоректный номер',
    invalidEmail: 'Неккоректный email'
  }
};
