import {useEffect, useState} from 'react';
import Slider from 'react-slick';
import * as cn from 'classnames';
import Container from '../Container';
import sliderConfig from './sliderConfig';
import {dataProducts} from '../../data/dataProducts';
import 'slick-carousel/slick/slick.scss';
import {dataSettings} from '../../data/dataSettings';
import Icon from '../Icon';
import {useModal} from '../modals/useModal';
import Modal from '../modals/Modal';
import ModalSuccess from '../modals/ModalSuccess';
import FormPrimary from '../forms/FormPrimary';
import './sliderProducts.scss';
import '../../styles/blocks/slider-common.scss';
import InfoBox from '../InfoBox';

const Rating = ({count}) => {
  if (!Number.isFinite(count)) return null;

  const floorCount = Math.trunc(count);
  const fraction = String(count).split('.')[1] || 0;
  const stars = Array.from(Array(floorCount).keys());

  return (
    <span className={'rating'}>
      {stars?.length &&
        stars.map((i) => {
          return <Icon key={i} name={'star'} />;
        })}
      {fraction > 0 && <Icon name={`star-${fraction}`} />}
    </span>
  );
};

const ViewPercent = ({percent}) => {
  if (!Number.isFinite(percent) || percent < 1) return null;
  return <span className={'percent'}>-{percent}%*</span>;
};

const ViewPrice = ({price, priceSale}) => {
  if (!Number.isFinite(price)) return null;
  return (
    <>
      {priceSale > 0 && (
        <span className={'price-amount'}>
          <strong>
            {priceSale} {dataSettings.priceCurrency}
          </strong>
        </span>
      )}
      {
        <strong>
          {priceSale > 0 ? (
            <span className={'price'}>
              <s>
                {price} {dataSettings.priceCurrency}
              </s>
            </span>
          ) : (
            <span className={'price-amount'}>
              {price} {dataSettings.priceCurrency}
            </span>
          )}
        </strong>
      }
    </>
  );
};

const MainProduct = ({
  id,
  title,
  description,
  text,
  price,
  percent,
  reviews,
  stars,
  image,
  setOrder
}) => {
  const [showInfo, setShowInfo] = useState(false);

  const pricePercent = percent ? (price / 100) * percent : 0;
  const priceSale = pricePercent ? Math.ceil(price - pricePercent) : 0;

  return (
    <div className={'slider-products__slide main-product box-primary'}>
      <div className={'main-product__image'}>
        {image[0]?.url?.length && (
          <img
            src={image[0].url}
            alt={title}
            width={'260'}
            height={'300'}
            loading={'lazy'}
          />
        )}
        {(description || text) && (
          <InfoBox
            description={description}
            text={text}
            showing={showInfo}
            setShow={setShowInfo}
          />
        )}
      </div>
      <div className={'main-product__price'}>
        <ViewPrice priceSale={priceSale} price={price} />
        <ViewPercent percent={percent} />
      </div>
      <div className={'main-product__title'}>
        <h4>{title}</h4>
      </div>
      <div className={'main-product__rating'}>
        <span className={'stars'}>
          <Rating count={stars} />
        </span>
        <span className={'main-product__review'}>{reviews}</span>
      </div>
      <div className={'main-product__order'}>
        <button
          className={'btn btn-primary btn--md btn--e-scale'}
          onClick={() => setOrder({title, priceSale, price})}
        >
          Заказать
        </button>
        <p className={'note'}>*при заказе комплекта</p>
      </div>
    </div>
  );
};
export default function SliderProducts() {
  const [activeCatId, setActiveCatId] = useState(1);
  const [activeOrder, setActiveOrder] = useState(null);
  const modalOrder = useModal();
  const modalSuccess = useModal();

  const activeCatIdx = dataProducts.findIndex((cat) => cat.id === activeCatId);

  const products =
    activeCatIdx >= 0 ? dataProducts[activeCatIdx].products : null;

  const setOrder = ({title, priceSale, price}) => {
    const activePrice = priceSale || price;
    setActiveOrder({title, price: activePrice});
    modalOrder.setOpen(true);
  };

  return (
    <section className={'section slider-products'}>
      <Modal
        isOpen={modalSuccess.isOpen}
        onClose={() => modalSuccess.setOpen(false)}
      >
        <ModalSuccess
          title="Ваш заказ отправлен"
          text="Наш менеджер перезвонит Вам в течении 20 минут!"
          onClose={() => modalSuccess.setOpen(false)}
        />
      </Modal>

      <Modal
        isOpen={modalOrder.isOpen}
        onClose={() => modalOrder.setOpen(false)}
      >
        <div className={'modal__content'}>
          <div className={'modal__title'}>
            <h3>Отправка заказа</h3>
          </div>
          <div className={'modal__subtitle'}>
            <strong>{activeOrder?.title && activeOrder.title}</strong>
          </div>
          <div className={'modal__subtitle'}>
            <p>Наш менеджер перезвонит Вам в течении 20 минут!</p>
          </div>

          {activeOrder && (
            <FormPrimary
              title={'Заказ товара'}
              action={'post_product_order'}
              data={{
                data: [{title: activeOrder.title}],
                price: activeOrder.price
              }}
              onSuccess={() => {
                modalOrder.setOpen(false);
                modalSuccess.setOpen(true);
              }}
            />
          )}
        </div>
      </Modal>

      <Container>
        <div className={'slider-products__heading heading'}>
          <h2>Каталог товаров</h2>
        </div>

        <div className={'slider-products__pane pane'}>
          {dataProducts?.length &&
            dataProducts.map(({id, catTitle}) => {
              return (
                <button
                  key={id}
                  className={cn(
                    'pane-tab btn btn-third btn--md btn--e-scale',
                    id === activeCatId ? 'active' : ''
                  )}
                  onClick={() => setActiveCatId(id)}
                >
                  {catTitle}
                </button>
              );
            })}
        </div>

        {products?.length && (
          <Slider {...sliderConfig} className={'slider-products__slider'}>
            {products.map((product, i) => {
              return (
                <div key={i}>
                  <MainProduct
                    key={`${activeCatId}-${i}`}
                    {...products[i]}
                    setOrder={setOrder}
                  />
                </div>
              );
            })}
          </Slider>
        )}
      </Container>
    </section>
  );
}
