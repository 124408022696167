const postQuery = async (url, body) => {
  const headers = {'Content-Type': 'application/json'};
  let error = false;

  const response = {
    data: null,
    error,
    errorMessage: null
  };

  const res = await fetch(url, {
    method: 'POST',
    headers,
    body
  });

  // console.log('---------getQuery---------');
  // console.log(json);

  if (!res || !res.ok) {
    error = `postQuery status: ${res.status}, ${res.statusText}`;
    response.error = true;
    response.errorMessage = error;
  } else {
    response.data = await res.json();
  }

  return response;
};

export const postForm = async ({url, action = null, body = null}) => {
  if (!url || !action || !body) {
    console.log(`Error: PostForm not data to send (url, action, body)`);
    return null;
  }

  const postBody = {...body, action};
  // console.log(postBody);

  const {error, errorMessage, data} = await postQuery(
    url,
    JSON.stringify(postBody)
  );

  if (error || !data) {
    console.log(`Error send: PostForm: ${errorMessage}`);
    return null;
  }

  return data;
};
