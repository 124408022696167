import {useEffect} from 'react';
import Modal from '../Modal';
import ModalSuccess from '../ModalSuccess';
import FormPrimary from '../../forms/FormPrimary';
import {useModal} from '../useModal';

export default function ModalFeedBack({
  title = '',
  opened = false,
  setShowModal
}) {
  const modalFeedBack = useModal();
  const modalSuccess = useModal();

  useEffect(() => {
    modalFeedBack.setOpen(opened);
  });
  // modalFeedBack.setOpen(true);

  return (
    <>
      <Modal
        isOpen={modalSuccess.isOpen}
        onClose={() => modalSuccess.setOpen(false)}
      >
        <ModalSuccess
          title="Ваше сообщение отправлено!"
          text="Наш менеджер перезвонит Вам в течении 20 минут!"
          onClose={() => modalSuccess.setOpen(false)}
        />
      </Modal>
      <Modal isOpen={modalFeedBack.isOpen} onClose={() => setShowModal(false)}>
        <div className={'modal__content'}>
          <div className={'modal__title'}>
            <h3>{title}</h3>
          </div>
          <div className={'modal__subtitle'}>
            <p>Наш менеджер перезвонит Вам в течении 20 минут!</p>
          </div>

          <FormPrimary
            title={title}
            action={'post_feedback'}
            data={null}
            onSuccess={() => {
              setShowModal(false);
              modalSuccess.setOpen(true);
            }}
          />
        </div>
      </Modal>
    </>
  );
}
