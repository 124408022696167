import Icon from '../Icon';

function SampleNextArrow(props) {
  const {className, style, onClick} = props;
  return (
    <div className={className} style={{...style}} onClick={onClick}>
      <Icon name="arrow" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const {className, style, onClick} = props;
  return (
    <div className={className} style={{...style}} onClick={onClick}>
      <Icon name="arrow" />
    </div>
  );
}

const sliderConfig = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 600,
  autoplay: true,
  autoplaySpeed: 5000,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        dots: true,
        arrows: false
      }
    }
  ]
};

export default sliderConfig;
