import {isMobile} from 'react-device-detect';
import {dataFeatures} from '../../data/dataFeatures';
import Container from '../Container';
import GradientEllipse from '../backgrounds/GradientEllipse';
import './Features.scss';

export default function Features() {
  return (
    <section className={'section features'}>
      <Container>
        <GradientEllipse
          type="primary"
          width="800px"
          height="800px"
          top="-200px"
          right={'-150px'}
        />
        <GradientEllipse
          type="secondary"
          width="800px"
          height="800px"
          bottom="-250px"
          left={'-150px'}
        />
        {!isMobile && (
          <img
            className={'bg-item bg-item--3'}
            src="/images/bgitems/3-min.png"
            width="540"
            height="574"
            alt="item"
            loading="lazy"
          />
        )}
        {!isMobile && (
          <img
            className={'bg-item bg-item--4'}
            src="/images/bgitems/4-min.png"
            width="515"
            height="572"
            alt="item"
            loading="lazy"
          />
        )}
        <div className="features__inner box-primary">
          <div className={'features__subheading subheading'}>
            <p className={'badge'}>{dataFeatures.subtitle}</p>
          </div>
          <div className={'features__heading heading'}>
            <h2>{dataFeatures.title}</h2>
          </div>

          <div className={'features__items'}>
            {dataFeatures?.items?.length &&
              dataFeatures.items.map(({title, text, imageUrl}, i) => {
                return (
                  <div key={i} className={'feature-item'}>
                    <div className={'feature-item__image'}>
                      <img
                        src={imageUrl}
                        height="100"
                        width="100"
                        alt={title}
                        loading="lazy"
                      />
                    </div>
                    <div className={'feature-item__title'}>
                      <h4>{title}</h4>
                    </div>
                    <div className={'feature-item__text'}>
                      <p>{text}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Container>
    </section>
  );
}
