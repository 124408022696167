import React from 'react';
import './QuizButtons.scss';
import Icon from '../../Icon';

function QuizButtons({
  activeStep,
  countProducts,
  countSteps,
  setActiveStep,
  setStatus,
  haveStepActiveProduct
}) {
  const PrevButton = () => {
    if (activeStep > 0) {
      const prevStep = activeStep - 1;
      return (
        <button
          className={'btn btn--md btn-secondary btn--e-scale'}
          onClick={() => setActiveStep(prevStep)}
        >
          <span className={'icon icon--left'}>
            <Icon name={'arrowThinkLeft'} />
          </span>
          <span>Предыдущий шаг</span>
        </button>
      );
    }
    return null;
  };

  const NextButton = () => {
    // console.log('--choiceId');
    // console.log(activeStep, countSteps);

    let disabled = true;
    if (haveStepActiveProduct()) {
      disabled = false;
    }

    if (countSteps > activeStep + 1) {
      // next step
      const nextStep = activeStep + 1;
      return (
        <button
          className={'btn btn--md btn-primary btn--e-scale'}
          onClick={() => setActiveStep(nextStep)}
          disabled={disabled}
        >
          <span>Следующий шаг</span>
          <span className={'icon icon--right'}>
            <Icon name={'arrowThinkRight'} />
          </span>
        </button>
      );
      // last step
    } else if (countSteps === activeStep + 1) {
      return (
        <button
          className={'btn btn--md btn-primary btn--e-scale'}
          onClick={() => {
            setStatus('finally');
          }}
          disabled={disabled}
        >
          <span>Выбрать подарок!</span>
          <span className={'icon icon--right'}>
            <Icon name={'arrowThinkRight'} />
          </span>
        </button>
      );
    }
    return null;
  };

  if (countProducts && countProducts > 0) {
    return (
      <div className={'quiz-buttons'}>
        <PrevButton />
        <NextButton />
      </div>
    );
  }
  return null;
}

export default QuizButtons;
