import React, {useState} from 'react';
import {dataAbout} from '../../data/dataAbout';
import Container from '../Container';
import ModalFeedBack from '../modals/ModalFeedBack';
import './About.scss';

export default function About() {
  const [showModal, setShowModal] = useState(false);
  return (
    <section className={'section about'}>
      <ModalFeedBack
        title={'Онлайн консультация'}
        opened={showModal}
        setShowModal={setShowModal}
      />
      <Container>
        <div className="about__inner">
          <div className="about__col">
            <img
              src={'/images/promo/girl3-min.png'}
              height="700"
              width="413"
              className={'about__image'}
              alt={dataAbout.person.name}
              loading="lazy"
            />
            <div className={'image-caption'}>
              <div className={'image-caption__name h3'}>
                {dataAbout.person.name}
              </div>
              <div className={'image-caption__status'}>
                <p>{dataAbout.person.status}</p>
              </div>
            </div>
          </div>

          <div className="about__col">
            <div className={'about__subheading subheading'}>
              <p className={'badge'}>{dataAbout.subtitle}</p>
            </div>
            <div className={'about__heading heading'}>
              <h2>{dataAbout.title}</h2>
            </div>
            <div className={'about__content'}>
              <h3 className={'about__description'}>{dataAbout.description}</h3>
              <p
                className={'about__text'}
                dangerouslySetInnerHTML={{__html: dataAbout.text}}
              />
              <div className={'about__footer'}>
                <button
                  className={'btn btn--md btn-primary btn--e-scale'}
                  onClick={() => setShowModal(true)}
                >
                  <span className={'btn-primary__bg'} />
                  <span className={'btn-primary__text'}>
                    Онлайн консультация
                  </span>
                </button>
                <p>{dataAbout.textFooter}</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
