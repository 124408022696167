import {NavLink} from 'react-router-dom';
import {INDEX_ROUTE} from '../../routes';
import LogoImage from './logo.png';
import './logo.scss';

export default function Logo() {
  return (
    <div className="logo">
      <NavLink to={INDEX_ROUTE}>
        <img src={LogoImage} alt={'d2e'} width={130} height={62} />
      </NavLink>
    </div>
  );
}
