export const dataFeatures = {
  title: '5 причин заказать спортивную одежду в нашем интернет-магазине:',
  subtitle: 'Женская спортивная одежда по доступным ценам!',
  items: [
    {
      title: 'Дизайнерская одежда от производителя',
      text: 'Эксклюзивная коллекция одежды от мультибрендового магазина женской спортивной одежды — Dress to Express',
      imageUrl: '/images/features/1-min.png'
    },
    {
      title: 'Стандарты качества мировых брендов',
      text: 'Только натуральные ткани в соответствии с требованиями американских и европейских рынков. Двойной контроль качества!',
      imageUrl: '/images/features/2-min.png'
    },
    {
      title: 'Доступные цены, гибкая система скидок',
      text: 'Мы предлагаем Вам подобрать эффектный лук по выгодной цене и получить гарантированный подарок!',
      imageUrl: '/images/features/3-min.png'
    },
    {
      title: 'Профессиональная онлайн консультация специалиста',
      text: 'Профессиональная консультация с подбором эксклюзивного лука для наиболее эффективных занятий спортом!',
      imageUrl: '/images/features/4-min.png'
    },
    {
      title: 'Оперативная доставка в течении 24 часов с момента заказа',
      text: 'Доставка в любую точку Украины. Оформите заказ прямо сейчас и получите свой эксклюзивный лук уже сегодня!',
      imageUrl: '/images/features/5-min.png'
    }
  ]
};
