import {isMobile, isTablet} from 'react-device-detect';
import QuizProduct from '../QuizProduct';
import './StepChoise.scss';
import StepStatus from './StepStatus';

function StepChoice({
  products,
  dataStep,
  activeStep,
  activeProducts,
  setActiveProducts,
  setPrice
}) {
  const activeProductIdx = activeProducts.findIndex(
    (item) => item.stepIdx === activeStep
  );

  const setActive = (id, priceComplect = 0) => {
    let newActiveProducts = null;

    const newActive = {
      stepIdx: activeStep,
      activeItemId: id
    };

    if (activeProductIdx >= 0) {
      newActiveProducts = [
        ...activeProducts.slice(0, activeProductIdx),
        newActive,
        ...activeProducts.slice(activeProductIdx + 1)
      ];
    } else {
      newActiveProducts = [...activeProducts, newActive];
    }

    setActiveProducts(newActiveProducts);

    // set price in step 2
    if (activeStep === 2 && priceComplect) {
      setPrice(priceComplect);
    }
  };

  return (
    <>
      <StepStatus num={activeStep + 1} title={dataStep?.title} />

      <div
        className={'step-choice'}
        style={{overflowX: isMobile || isTablet ? 'scroll' : 'auto'}}
      >
        <div className={'step-choice__products'}>
          {products?.length &&
            products.map((product) => {
              return (
                <QuizProduct
                  key={product.id}
                  {...product}
                  active={
                    activeProductIdx >= 0 &&
                    product.id === activeProducts[activeProductIdx].activeItemId
                  }
                  setActive={setActive}
                />
              );
            })}
        </div>
      </div>
    </>
  );
}

export default StepChoice;
