import React, {useEffect, useState} from 'react';
import * as cn from 'classnames';
import {NavLink} from 'react-router-dom';
import {PRIVATE_POLICY_ROUTE} from '../../../routes';
import Icon from '../../Icon';
import {messages} from '../../../lib/messages';
import './CheckAgree.scss';

export default function CheckAgree({
  name,
  value: checked,
  required,
  error,
  setError,
  deleteError,
  changeField
}) {
  const onChange = (e) => {
    changeField(name, e.target.checked);
    if (required && !e.target.checked) {
      setError(name, messages.ru.empty);
    } else {
      deleteError(name);
    }
  };

  return (
    <label className={'check-agree'}>
      <span
        className={cn(
          'check-agree__icon',
          error ? 'error' : '',
          checked ? 'checked' : ''
        )}
      >
        <input
          type={'checkbox'}
          id={`form-field-${name}`}
          name={name}
          checked={checked}
          onChange={onChange}
        />
        <Icon name={'check'} className={'icon'} />
      </span>
      <span className={'check-agree__text'}>
        <span>Вы соглашаетесь с условиями </span>
        <NavLink to={PRIVATE_POLICY_ROUTE}>
          обработки персональных данных
        </NavLink>
      </span>
    </label>
  );
}
