export const dataSettings = {
  city: 'г. Николаев',
  address: 'пр-т Центральный 78а',
  fullAddress: 'г. Николаев, проспект Центральный 78а',
  schedule: 'Ежедневно с 10:00 до 19:00',
  phoneNumber: '+380 (98) 311 32 19',
  priceCurrency: 'грн',
  socials: {
    instagram: {
      title: '@d2e.official',
      url: 'https://www.instagram.com/d2e.official/'
    }
  },
  map: {
    latitude: 46.967273,
    longitude: 31.991172
  }
};
