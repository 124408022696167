import React from 'react';
import './ModalSuccess.scss';

export default function ModalSuccess({onClose, title = '', text = ''}) {
  return (
    <div className={'modal-success'}>
      <h4>{title}</h4>
      <p>{text}</p>
      <button
        className={'btn btn-primary btn--md btn--e-scale'}
        onClick={() => onClose()}
      >
        Закрыть
      </button>
    </div>
  );
}
