import React, {useEffect, useState} from 'react';
import * as cn from 'classnames';
import {dataExtendSteps} from '../../../data/dataProducts';
import './QuizExternalModal.scss';
import Modal from '../../modals/Modal';
import QuizFormSendProducts from '../QuizFormSendProducts';
import {useModal} from '../../modals/useModal';
import ModalSuccess from '../../modals/ModalSuccess';

export default function QuizExternalModal({
  activeProducts,
  price: stepsPrice,
  closeParent
}) {
  const {title, description, image, price} = dataExtendSteps.corset.products[0];

  const {isOpen: isOpenSendModal, setOpen: setOpenSendModal} = useModal();
  const {isOpen: isOpenSuccessModal, setOpen: setOpenSuccessModal} = useModal();
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (status === 'success') {
      setOpenSendModal(false);
      setOpenSuccessModal(true);
    }
  }, [status]);

  return (
    <>
      {/* dialog success */}
      <Modal
        isOpen={isOpenSuccessModal}
        onClose={() => setOpenSuccessModal(false)}
      >
        <ModalSuccess
          title="Ваш заказ отправлен"
          text="Наш менеджер перезвонит Вам в течении 20 минут!"
          onClose={() => {
            setOpenSuccessModal(false);
            closeParent();
          }}
        />
      </Modal>
      {/* dialog sending */}
      <Modal isOpen={isOpenSendModal} onClose={() => setOpenSendModal(false)}>
        <QuizFormSendProducts
          title={'Дозаказ квиз + корсет'}
          activeProducts={activeProducts}
          price={price + stepsPrice}
          setStatus={setStatus}
        />
      </Modal>

      <div className={'quiz-external-modal__inner'}>
        <div className={'quiz-external-modal__title h4'}>
          Спасибо, Ваша заявка принята
        </div>

        <div className={'quiz-external-modal__main'}>
          {/* status */}
          <div className={'quiz-external-modal__status'}>
            <div className={'quiz-status'}>
              <div className={'quiz-progress'} />

              <div className={'quiz-status__text'}>
                Еще один приятный бонус для Вас!
              </div>
              <div className={'quiz-status__ico'}>
                <img src={'/images/ico_present-min.png'} alt={'present'} />
              </div>
            </div>
          </div>

          <div className={'quiz-external-modal__content'}>
            {/* product */}
            <div className={'quiz-external-modal__product'}>
              <div className={'quiz-product'}>
                <div className={'quiz-product__main'}>
                  <div className={'icon-info'} />
                  <div className={'quiz-product__image'}>
                    <img
                      src={image[0].url}
                      alt={title}
                      width={300}
                      height={200}
                    />
                  </div>
                  <div className={'quiz-product__info'}>
                    {description && (
                      <p dangerouslySetInnerHTML={{__html: description}} />
                    )}
                  </div>
                </div>

                <div className={'quiz-product__title'}>
                  <h4>{title}</h4>
                </div>
              </div>
            </div>

            <div className={'quiz-external-modal__promo quiz-promo'}>
              <h4 className={'quiz-promo__title'}>
                Закажите прямо сейчас по супер цене!
              </h4>

              <div className={'quiz-promo__pricing'}>
                <span className={'quiz-promo__product-price'}>500 грн</span>
                <s>990 грн</s>
                <span className={'quiz-promo__percent'}>-50%*</span>
              </div>

              <span className={'quiz-promo__note'}>
                *акционная цена при заказе комплекта
              </span>

              <strong>Ваш комплект + корсет Komplique</strong>
              <div className={'quiz-promo__amount'}>{stepsPrice + 500} грн</div>

              <div className="form-field form-field__submit">
                <button
                  className={'btn btn-primary btn--md btn--e-scale'}
                  onClick={() => setOpenSendModal(true)}
                >
                  Отправить заказ
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* background image */}
        <div className={'quiz-external-modal__image'}>
          <img
            src="/images/corset-min.png"
            width={200}
            height={300}
            alt="Желаешь тонкую талию? Легко!!!"
          />

          <div className={'quiz-external-modal__image-title h2'}>
            Желаешь тонкую талию? Легко!!!
          </div>
        </div>
      </div>
    </>
  );
}
