import React from 'react';
import Container from '../components/Container';

function Agreement() {
  return (
    <div className={'page'}>
      <Container>
        <h2 className={'page__heading'}>Пользовательское соглашение</h2>
        <p>В процессе ...</p>
      </Container>
    </div>
  );
}

export default Agreement;
