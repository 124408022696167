import React, {useEffect, useRef, useState} from 'react';
import {isMobile} from 'react-device-detect';
import * as cn from 'classnames';
import InfoBox from '../../InfoBox';
import './QuizProduct.scss';

function QuizProduct({
  id,
  title,
  description,
  text,
  image,
  priceComplect,
  active,
  setActive
}) {
  const refProduct = useRef(null);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    // console.log('--Quiz product');
    if (active) {
      refProduct.current.scrollIntoView({
        block: 'nearest',
        inline: 'nearest'
      });
    }
  });

  return (
    <div
      ref={refProduct}
      className={'quiz-product'}
      onClick={(e) => {
        setActive(id, priceComplect);
      }}
    >
      <div className={cn('quiz-product__main', active ? 'active' : '')}>
        <div className={'quiz-product__image'}>
          <img
            src={image[0].url}
            alt={title}
            width={300}
            height={200}
            loading="lazy"
          />
        </div>

        <div className={'check-rounded'} />

        {(description || text) && (
          <InfoBox
            description={description}
            text={text}
            showing={showInfo}
            setShow={setShowInfo}
          />
        )}
      </div>
      <div className={'quiz-product__title'}>
        <h4>{title}</h4>
      </div>
    </div>
  );
}

export default QuizProduct;
