import React, {useEffect} from 'react';
import PhoneInput from 'react-phone-input-2';
import debounce from 'lodash.debounce';
import 'react-phone-input-2/lib/style.css';
import cn from 'classnames';
import {messages} from '../../../lib/messages';
import './PhoneField.scss';

const changeDebounce = debounce((func) => func(), 130);

export default function PhoneField({
  name,
  placeholder,
  value,
  required = false,
  changeField,
  error = null,
  setError,
  deleteError
}) {
  useEffect(() => {
    if (required && (!value || !value.length)) {
      setError(name, messages.ru.empty);
    }
  }, [value]);

  const validate = (changeValue, country) => {
    // console.log(country.dialCode);
    // console.log(changeValue.length);

    if (changeValue?.length) {
      if (changeValue.length > 11) {
        deleteError(name);
        return true;
      } else {
        setError(name, messages.ru.invalidPhone);
        return false;
      }
    } else {
      if (required) {
        setError(name, messages.ru.empty);
        return false;
      }
      return true;
    }
  };

  const onChange = (changeValue, country) => {
    changeDebounce(() => {
      validate(changeValue, country);
      changeField(name, changeValue);
    });
  };

  return (
    <>
      <div className="form-field__phone-inner">
        <PhoneInput
          id={`form-field-${name}`}
          name={name}
          country={'ua'}
          onlyCountries={['ua']}
          // autoComplete="off"
          placeholder=""
          disableDropdown={true}
          inputClass={error ? 'error' : 'valid'}
          onChange={onChange}
          value={value}
        />

        <label
          htmlFor={`form-field-${name}`}
          data-title={error ?? messages.ru.phone}
          className={cn('form-field__label focused', error ? 'error' : 'valid')}
        />
      </div>
    </>
  );
}
