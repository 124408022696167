import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {INDEX_ROUTE, publicRoutes} from '../routes';

function AppRouter() {
  return (
    <Switch>
      {publicRoutes?.length &&
        publicRoutes.map(({path, Component}) => (
          <Route key={path} path={path} component={Component} exact />
        ))}
      <Redirect to={INDEX_ROUTE} />
    </Switch>
  );
}

export default AppRouter;
