import React from 'react';
import FormPrimary from '../../forms/FormPrimary';
import {dataExtendSteps, dataProducts} from '../../../data/dataProducts';

export default function QuizFormSendProducts({
  title = '',
  activeProducts,
  setStatus,
  price
}) {
  console.log('--QuizFormSendProducts');
  console.log(activeProducts, price);

  // merge dataProducts, dataExtendSteps
  const allSteps = [
    ...dataProducts,
    {
      id: dataExtendSteps.finally.id,
      title: dataExtendSteps.finally.title,
      products: [...dataExtendSteps.finally.products]
    }
  ];

  const sendingProducts = activeProducts.map(({stepIdx, activeItemId}) => {
    return allSteps[stepIdx]?.products?.find(
      (prod) => prod.id === activeItemId
    );
  });

  const onSuccess = () => {
    setStatus('success');
  };

  // console.log(allSteps);
  // console.log(sendingProducts);

  return (
    <div className={'modal__content'}>
      <div className={'modal__title'}>
        <h3>Отправка заказа</h3>
      </div>
      <div className={'modal__subtitle'}>
        <p>Наш менеджер свяжется с Вами в течении 20 минут!</p>
      </div>
      <FormPrimary
        title={title}
        action={'post_quiz_order'}
        data={{data: sendingProducts, price}}
        onSuccess={onSuccess}
      />
    </div>
  );
}
