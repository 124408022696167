import React from 'react';
import SliderPromo from '../../components/SliderPromo';
import Quiz from '../../components/Quiz';
import About from '../../components/About';
import Features from '../../components/Features';
import SliderProducts from '../../components/SliderProducts';
import DeliveryPayment from '../../components/DeliveryPayment';
import SectionContact from '../../components/SectionContact';

function IndexPage() {
  return (
    <>
      <SliderPromo />
      <Quiz />
      <About />
      <Features />
      <SliderProducts />
      <DeliveryPayment />
      <SectionContact />
    </>
  );
}

export default IndexPage;
