import cn from 'classnames';
import debounce from 'lodash.debounce';
import {useEffect, useRef} from 'react';
import Icon from '../Icon';
import './TopBtn.scss';

const scroll = (btn) => {
  if (btn && window.pageYOffset > 1000) {
    btn.classList.add('visible');
  } else {
    btn.classList.remove('visible');
  }
};

export default function TopBtn() {
  const btnRef = useRef();

  useEffect(() => {
    document.addEventListener(
      'scroll',
      debounce(() => scroll(btnRef.current), 100)
    );

    return () => {
      document.removeEventListener(
        'scroll',
        debounce(() => scroll(btnRef.current), 100)
      );
    };
  }, []);

  const onClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={'top-btn'} ref={btnRef} onClick={onClick}>
      <Icon name={'arrow'} />
    </div>
  );
}
