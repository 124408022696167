import {useEffect, useRef} from 'react';
import Modal from '../modals/Modal';
import Icon from '../Icon';
import {useModal} from '../modals/useModal';
import './ButtonPlay.scss';

export default function ButtonPlay() {
  const modalVideo = useModal();
  const refVideo = useRef(null);

  useEffect(() => {
    if (refVideo && refVideo.current) {
      refVideo.current.volume = 0.1;
    }
  });

  return (
    <>
      <Modal
        className={'modal-video'}
        isOpen={modalVideo.isOpen}
        onClose={() => modalVideo.setOpen(false)}
        overflow={'hidden'}
      >
        {modalVideo.isOpen && (
          <video
            ref={refVideo}
            controls
            autoPlay
            controlsList="nodownload"
            disablePictureInPicture
            // onContextMenu={(e) => e.preventDefault()}
          >
            <source src="/video.mp4" type="video/mp4" />
          </video>
        )}
      </Modal>
      <div className={'btn btn-play'} onClick={() => modalVideo.setOpen(true)}>
        <div className={'icon-play'}>
          <Icon name={'play'} />
        </div>
      </div>
    </>
  );
}
