import {NavLink} from 'react-router-dom';
import Container from '../Container';
import {PRIVATE_POLICY_ROUTE, USER_AGREEMENT_ROUTE} from '../../routes';
import './Footer.scss';

export default function Footer() {
  const date = new Date();
  return (
    <section className={'footer'}>
      <Container>
        <div className={'footer__inner'}>
          <p>
            <NavLink to={PRIVATE_POLICY_ROUTE}>
              Политика конфиденциальности
            </NavLink>
          </p>
          <p>{date.getFullYear()} Copyright © Все права защищены</p>
          <p>
            <NavLink to={USER_AGREEMENT_ROUTE}>
              Пользовательское соглашение
            </NavLink>
          </p>
        </div>
      </Container>
    </section>
  );
}
